<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>Send Email</span>
      </p>
    </header>
    <section class="modal-card-body">
      <slot name="text-content" />
      <!-- <vue-email :to-emails="toEmails"
        :cc-emails="ccEmails"
        :bcc-emails="bccEmails"></vue-email> -->
    </section>
    <footer class="modal-card-foot">
      <div :style="`width: 100%; display: flex; justify-content: space-between;`">
        <div style="align-self: flex-start;">
          <button v-if="false"
            class="button tooltip is-tooltip-topleft"
            @click="attach()"
            data-tooltip="Attach media">
            <span class="icon">
              <i class="mdi mdi-paperclip mdi-18px" />
            </span>
          </button>
        </div>
        <div style="align-self: flex-end;">
          <button class="button is-primary tooltip is-tooltip-topright"
            @click="send()"
            data-tooltip="Send Email"
            :disabled="!enableSend">
            <span class="icon">
              <i class="mdi mdi-send mdi-18px" />
            </span>
            <span>Send</span>
          </button>
          <button class="button is-primary tooltip"
            v-show="enableAttach"
            @click="attach()"
            :data-tooltip="[!toggleAttachButton ? 'Attach images' : 'Done']"
            :disabled="!enableAttach">
            <span v-if="!toggleAttachButton"
              class="icon is-small">
              <i class="mdi mdi-18px mdi-paperclip" />
            </span>
            <span v-else>Done</span></button>
          <button class="button tooltip is-tooltip-topright"
            @click="discard()"
            data-tooltip="Discard Email">
            <span class="icon">
              <i class="mdi mdi-delete mdi-18px" />
            </span>
          </button>
        </div>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'

export default {
  name: 'VueEmailModal',
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    },
    toEmails: {
      type: Array,
      default: () => []
    },
    ccEmails: {
      type: Array,
      default: () => []
    },
    bccEmails: {
      type: Array,
      default: () => []
    },
    enableSend: {
      type: Boolean,
      default: false
    },
    enableAttach: {
      type: Boolean,
      default: true
    },
    toggleAttachButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  computed: {},
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    send() {
      // this.isActive = false
      // this.$emit('update:active', false)
      this.$emit('send')
    },
    attach() {
      this.$emit('show-attach')
    },
    discard() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('discard')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
